/**
 * @flow
 */
import axios from "axios";
import {updateUser} from "./userReducer";
import {api} from "./service";

const useDev = window.location.host === "localhost:3000" || window.location.host === "coship.homekling.com";
const API_URL_DEV = "http://coship.homekling.com";
// const API_URL_PRD = useDev ? API_URL_DEV : "https://adm.coship.ca";
const API_URL_PRD = useDev ? API_URL_DEV : "https://bms.coship.ca";

const API_URL = process.env.NODE_ENV === "production" ? API_URL_PRD : API_URL_DEV;

function showDialog() {
}

class API {
  token;
  dispatch;
  history;

  async login(data) {
    const action = "ReqLogin";
    // const timeDelta = new Date().getTimezoneOffset();
    const reqData = {action, data};
    const {data: res} = await this.sendList("member/login/LOGIN", reqData);
    if (res) {
      const user = res[0];
      this.setUserAndToken(user);
      return user;
    } else {
      return null;
    }
  }

  async queryUser(id) {
    const {data} = await this.sendList("member/ViewData", {action: "CustomerData", data: {id}});
    return data?.[0];
  }

  async queryToken() {
    const {data} = await this.sendList("member/login/TOKEN", {});
    if (data?.[0]?.token) {
      this.token = data[0].token;
      return true;
    } else {
      return false;
    }
  }

  async logout() {
    this.token = undefined;
    sessionStorage.removeItem("@token");
    sessionStorage.removeItem("@user");
    updateUser(this.dispatch, {});
  }

  async registerUser(data) {
    const action = "SetCustomer";
    const reqData = {action, data};
    return this.sendCUD("member/CUD", reqData);
  }

  async requestQuote(data) {
    const action = "SetOnlineRequest";
    const reqData = {action, data};
    return this.sendCUD("member/CUD", reqData);
  }

  async getBLList(id) {
    return this.sendList("member/ListData", {action: "ListHistoryBL", data: {id}});
  }

  async getTrackingData(blNo, lang) {
    const token = this.getToken();
    if (!token) {
      await this.queryToken();
    }
    return this.sendList("member/ViewData", {action: "BLTrackingData", data: {blNo, lang}});
  }

  async getEditData(blNo) {
    const token = sessionStorage.getItem("@token");

    const res = await axios.request({
      url: `${API_URL}/member/ViewData`,
      method: "POST",
      data: {action: "TradeBLData", data: {blNo: blNo}},
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
    return res;
    //return this.sendList('member/ViewData', {action: 'TradeBLData', data: {blNo: blNo}});
  }

  async delEditData(blNo) {
    const token = sessionStorage.getItem("@token");

    const res = await axios.request({
      url: `${API_URL}/member/CUD`,
      method: "POST",
      data: {action: "DelMemberBL", data: {blNo: blNo}},
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
    return res;
  }

  async sendList(path, reqData, doNotShowError) {
    const res = await this.send(path, reqData, doNotShowError);
    return res ?? {data: null, totalCount: 0};
  }

  async sendCUD(path, reqData, doNotShowError) {
    const res = await this.send(path, reqData, doNotShowError);
    if (res?.data?.[0].ret === true) {
      return true;
    } else if (res?.data?.[0]) {
      return res.data[0];
    } else if (typeof res === "string") {
      return res;
    } else {
      return false;
    }
  }

  async send(path, reqData, doNotShowError) {
    try {
      //process.env.NODE_ENV !== 'production' && console.log('[API] REQ', path, reqData);
      const res = await axios.request({
        url: `${API_URL}/${path}`,
        method: "POST",
        data: reqData,
        headers: {
          Authorization: `bearer ${this.getToken()}`,
        },
      });
      process.env.NODE_ENV !== "production" && console.log("[API]", path, reqData, res);
      const {err_message: message, data, totalcount: totalCount = 0} = res.data;

      if (!message) {
        return {data, totalCount};
      } else {
        const msg = message.toLowerCase();
        if (msg === "login") {
          sessionStorage.clear();
          document.location.href = "/login";
        } else if (msg.startsWith("error")) {
          if (doNotShowError !== true) {
            const messages = msg.split("#");
            if (messages.length === 2) {
              showDialog(`Something went wrong! (${messages[1]})`, "System Error", "danger");
            } else {
              showDialog("Something went wrong!", "System Error", "danger");
            }
          }
        } else {
          if (message) {
            return message;
          }

          if (doNotShowError !== true) {
            showDialog(message, "System Error", "danger");
          }
        }
      }
    } catch (error) {
      if (error?.isAxiosError && error?.response?.status === 401) {
        document.location.href = "/login";
      } else {
        if (doNotShowError !== true) {
          showDialog("Something went wrong!", "System Error", "danger");
        }
      }
    }
  }

  getToken() {
    return this.token ?? sessionStorage.getItem("@token");
  }

  setUserAndToken(user) {
    const {token} = user;
    this.token = token;
    sessionStorage.setItem("@token", token);
    sessionStorage.setItem("@user", JSON.stringify(user));
    updateUser(this.dispatch, {...user, ready: true});
  }

  async saveBLData(dropzoneId, blNo, {blId, jcommon, jcustomer, jshipment}) {
    if (dropzoneId) {
      // 드랍존 아이디가 패러미터로 넘어 오면 사용자 입력이므로 반드시 토큰을 쿼리해야함!
      // 드랍존 관리자인 경우 로그인시 이미 토큰이 존재하므로 토큰을 쿼리할 필요 없음!
      await this.queryGuestToken(dropzoneId);
    }
    const reqData = {
      action: "SetBLEntry",
      data: {blId, blNo: blNo, jCommon: jcommon, jCustomer: jcustomer, jShipment: jshipment},
    };
    return this.sendCUD("member/CUD", reqData);
  }

  //https://coship.ca/application/form?s=MA&d=CANADA&n=C 로 신청했을때 보내는 api
  async saveBLDataForUPS(dropzoneId, blNo, {upsId, staffId, keyStr, blId, jcommon, jcustomer, jshipment}) {
    if (!this.token) {
      await api.queryToken();
    }
    if (dropzoneId) {
      await this.queryGuestToken(dropzoneId);
    }

    const reqData = {
      action: "SetUPSEntry",
      data: {upsId, staffId, keyStr, blId, blNo: blNo, jCommon: jcommon, jCustomer: jcustomer, jShipment: jshipment},
    };
    return this.sendCUD("member/CUD", reqData);
  }

  async queryGuestToken(dropZoneId) {
    const reqData = {
      action: "ReqDummyToken",
      data: {
        dropZoneId: parseInt(dropZoneId),
        timeDelta: new Date().getTimezoneOffset(),
      },
    };

    const {data} = await this.sendList("vendor/login/LOGIN", reqData);
    if (data?.[0]?.token) {
      this.token = data[0].token;
      return true;
    } else {
      return false;
    }
  }

  async getFreightPrice({portType, grossWeightKg, length, width, height, packageCount}) {
    const reqData = {
      action: "GetFreightPrice",
      data: {portType, grossWeightKg, length, width, height, package: packageCount},
    };
    return this.sendList("vendor/GetData", reqData);
  }

  async getUPSData({upsId, staffId, keyStr}) {
    if (staffId !== 'null' && keyStr !== 'null') {
      await api.queryToken();
    }
    const reqData = {
      action: "UPSData",
      data: {upsId, staffId, keyStr},
    };
    return this.sendList("member/ViewData", reqData);
  }


}


export default API;
